@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
body {
  color: #000;
  font-family: sans-serif;
  font-size: 15px;
}

.btn-purple {
  background-color: rgba(44, 53, 124, 0.8);
  color: #fff !important;
  border: rgba(44, 53, 124, 0.8) 1px solid !important;
}
.btn-purple:hover, .btn-purple:active, .btn-purple:disabled {
  background-color: rgba(57, 69, 162, 0.8) !important;
}

.ErrorText,
.input-error {
  color: #f00;
  font-size: 12px;
  font-style: italic;
}

.required-icon {
  margin: 0 2px;
  color: #f00;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.throbble-wrapper {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
}
.throbble-wrapper .throbble-inner {
  width: 500px;
  min-height: 120px;
  padding: 0 20px;
  color: #fff;
  background-color: rgba(44, 53, 124, 0.8);
  border: 2px solid rgba(250, 0, 0, 0.8);
  border-radius: 10px;
  text-align: center;
}
.throbble-wrapper .throbble-inner .spinner-block {
  border-radius: 50%;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 2px -2px 2px;
  font-size: 17px;
  margin: 0 auto;
}
.throbble-wrapper .throbble-inner .message-block {
  font-size: 15px;
}/*# sourceMappingURL=QRVisitors.css.map */