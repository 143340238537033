.throbble-wrapper {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    position: fixed;
    background: rgba($color: #fff, $alpha: 0.6);

    .throbble-inner {
        width: 500px;
        min-height: 120px;
        padding: 0 20px;
        color: #fff;
        background-color: $purple-color;
        border: 2px solid $red-color;
        border-radius: 10px;
        text-align: center;

        .spinner-block {
            border-radius: 50%;
            width: fit-content;
            padding: 2px 2px -2px 2px;
            font-size: 17px;
            margin: 0 auto;
        }

        .message-block {
            font-size: 15px;
        }
    }
}