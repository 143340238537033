﻿@import "../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../node_modules/bootstrap/scss/_functions.scss";

$purple-color: rgba(44, 53, 124, 0.8);
$red-color: rgba(250, 0, 0, 0.8);

body {
  color: #000;
  font-family: sans-serif;
  font-size: 15px;
}

.btn-purple {
  background-color: $purple-color;
  color: #fff !important;
  border: $purple-color 1px solid !important;

  &:hover,
  &:active,
  &:disabled {
    background-color: lighten($color: $purple-color, $amount: 10) !important;
  }
}

.ErrorText,
.input-error {
  color: #f00;
  font-size: 12px;
  font-style: italic;
}

.required-icon {
  margin: 0 2px;
  color: #f00;
}
@import "navMenu";
@import "syncfusion";
@import "throbble";